import React, { FC } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { DownloadAppDesktopProps } from 'components/presentational/DownloadApp/DownloadAppDesktop'
import { createAppUniversalDesktopLink } from 'components/presentational/DownloadApp/DownLoadAppDesktop.functions'
import { Image } from './DownLoadAppDesktop.styled'

export const DownLoadAppDesktopIcon: FC<DownloadAppDesktopProps> = ({
  playStore,
  appStore,
  playStoreLink,
  appStoreLink,
}) => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  const appLink = createAppUniversalDesktopLink(partnerId)
  return (
    <>
      <a href={playStoreLink || appLink} target={'_blank'} rel="noreferrer">
        <Image
          src={require('./play-store.svg')}
          {...playStore}
          alt={'Google Play'}
        />
      </a>
      <a href={appStoreLink || appLink} target={'_blank'} rel="noreferrer">
        <Image
          src={require('./app-store.svg')}
          {...appStore}
          alt={'App Store'}
        />
      </a>
    </>
  )
}
