import React, { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { RouterLink } from '../presentational/link'
import { fontFamily, appBannerPopUpRulesCookie } from '../../constants'
import { confidentialityLayoutPath } from '../layout/MambaLayout/paths'
import { media } from '../presentational'
import { useDispatch } from 'react-redux'
import { updateSystemCookiesBannersAction } from 'actions/system/systemAction'
import {
  setCookieBannerPopUpRulesPath,
  pathnameFormName,
} from 'common-constants/formAction'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { CloseSvg } from 'components/presentational/svg/CloseSvg'
import { StyledVisuallyHiddenText } from 'components/presentational/VisuallyHidden'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { setCookieValue } from '../../../client/functions/setCookie'
import { isPureDevMode } from 'functions/isPureDevMode'

export const BannerPopUpRulesCookie: FC = () => {
  const dispatch = useDispatch()
  const {
    cookies,
    authorized,
    pathname,
    yandexTurboApp,
  } = useShallowEqualSelector(
    ({
      systemReducer: { cookies, yandexTurboApp },
      authorizationReducer: { authorized },
      router: {
        location: { pathname },
      },
    }) => ({
      cookies,
      authorized,
      pathname,
      yandexTurboApp,
    })
  )

  const { baseUrl } = useLayoutContext()
  const bannerCookieValue = cookies[appBannerPopUpRulesCookie]

  if (isPureDevMode()) {
    return null
  }

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    setCookieValue(appBannerPopUpRulesCookie, 'true', 3650)
    dispatch(updateSystemCookiesBannersAction())
  }

  if (!bannerCookieValue && !authorized && !yandexTurboApp) {
    return (
      <Wrapper>
        <WrapperInner>
          <form
            id={'closeCookieBanner'}
            action={setCookieBannerPopUpRulesPath}
            method={'POST'}
          >
            <input type={'hidden'} name={pathnameFormName} value={pathname} />
            <Close
              type={'submit'}
              onClick={handleClick}
              data-name={'cookie-message-close-action'}
            >
              <CloseSvg stroke="#46aae9" width="18" height="18" />
              <StyledVisuallyHiddenText>
                <FormattedMessage
                  id={'app.link.close'}
                  defaultMessage={'Закрыть'}
                />
              </StyledVisuallyHiddenText>
            </Close>
            <FormattedMessage
              id={'cookie.notice'}
              defaultMessage={
                'Наш сайт использует файлы cookie. Продолжая использовать сайт, вы даете на это согласие в соответствии с <link>Политикой в отношении обработки персональных данных</link>.'
              }
              values={{
                link: (chunks) => (
                  <RouterLink
                    to={mergeAllUrls(baseUrl, confidentialityLayoutPath)}
                    data-name={'cookie-message-url-action'}
                  >
                    {chunks}
                  </RouterLink>
                ),
              }}
            />
          </form>
        </WrapperInner>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled.section`
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  font-family: ${fontFamily};
  font-size: 12px;
  color: #9d9d9d;
  z-index: 7;
`
const WrapperInner = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 20px;

  ${media.phone`
    padding: 10px 48px 10px 20px;
  `};
`

const Close = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: 0;

  ${media.phone`
    transform: none;
    right: 13px;
    top: 10px;
  `};
`
