import { jsonSuccessAction } from 'actions/jsonSuccessAction'
import { createNewLanguagePath } from 'functions/language'
import { fullPageReload } from 'functions/fullPageReload'
import { changeLanguageApi } from 'api/settings/changeLanguageApi'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const changeLanguageAction = (value: string, pathname: string) => ({
  type: CHANGE_LANGUAGE,
  promise: () =>
    changeLanguageApi(value).then(
      jsonSuccessAction(() => {
        const newPath = createNewLanguagePath(pathname, value)
        fullPageReload(newPath)
      })
    ),
})
