import React, { ChangeEventHandler, FC, useCallback, useRef } from 'react'
import { StaticSelect } from '../presentational/input'
import {
  changeLanguagePath,
  languageFormName,
  pathnameFormName,
} from 'common-constants/formAction'
import { findLocaleWithInternationalFallback } from 'functions/locale'
import { css } from 'styled-components'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useDispatch } from 'react-redux'
import { LOCALES } from 'common-constants/locale'
import { changeLanguageAction } from 'actions/settings/changeLanguageAction'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { isServiceWorkerActive } from 'functions/pwa/isServiceWorkerActive'

// TODO есть еще три использования позже переделать
const customLabelSelectCss = css`
  position: relative;
`

export const LangSelect: FC = () => {
  const formLanguage = useRef<HTMLFormElement | null>(null)

  const dispatch = useDispatch()

  const { locale, pathname, authorized } = useShallowEqualSelector(
    ({
      systemReducer: { locale },
      authorizationReducer: { authorized },
      router: {
        location: { pathname },
      },
    }) => ({ locale, pathname, authorized })
  )

  const handleChange = useCallback<ChangeEventHandler>(
    async ({ target }) => {
      if (authorized) {
        return dispatch(changeLanguageAction(target['value'], pathname))
      } else if (isPwaSupported()) {
        /** из-за асинхронщины, нужно запомнить локаль */
        const locale = target['value']
        const hasActiveWorker = await isServiceWorkerActive()

        if (hasActiveWorker) {
          return dispatch(
            updateShellAction({
              locale,
              reload: true,
            })
          )
        }
      }

      formLanguage.current!.submit()
    },
    [authorized, dispatch, pathname]
  )

  const externalLocale = findLocaleWithInternationalFallback(locale)

  return (
    <form
      id={'languageSelect'}
      action={changeLanguagePath}
      method={'POST'}
      ref={formLanguage}
    >
      <input type={'hidden'} name={pathnameFormName} value={pathname} />
      <StaticSelect
        name={languageFormName}
        data-name={'lang-select'}
        value={externalLocale}
        onChange={handleChange}
        onBlur={handleChange}
        customLabelCss={customLabelSelectCss}
        defaultColor={true}
      >
        {LOCALES.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </StaticSelect>
    </form>
  )
}
