import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 335px;
`

export const Image = styled.img`
  display: block;
`

export const DownLoadAppDesktopWrapper = styled.div`
  display: flex;
  align-items: center;

  & a {
    ${(props) => props.theme.marginRight}: 20px;
  }

  & a:last-child {
    ${(props) => props.theme.marginRight}: 0;
  }
`
