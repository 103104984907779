import { isYonja } from 'common/constants'
import {
  defaultLandingType,
  threeFeaturesLandingType,
} from 'components/landing/landing.constants'

export const onboardingDefaultFooterWeb = 'onboarding_default_footer_web'
export const onboardingDefaultFooterTouch = 'onboarding_default_footer_touch'
export const onboardingThreeFeaturesFooterWeb =
  'onboarding_three-features_footer_web'
export const onboardingThreeFeaturesFooterTouch =
  'onboarding_three-features_footer_touch'
export const onboardingThreeFeaturesUpperTouch =
  'onboarding_three-features_upper_touch'

export const upperPlaceType = 'upper'
const androidOsType = 'android'

export function findOnboardingType(
  onboarding = '',
  mobile = false,
  place = ''
) {
  if (onboarding === threeFeaturesLandingType) {
    if (mobile) {
      if (place === upperPlaceType) {
        return onboardingThreeFeaturesUpperTouch
      }
      return onboardingThreeFeaturesFooterTouch
    }
    return onboardingThreeFeaturesFooterWeb
  }

  if (onboarding === defaultLandingType) {
    if (mobile) {
      return onboardingDefaultFooterTouch
    }
    return onboardingDefaultFooterWeb
  }
}

export function createAppLinkMamba(
  osType,
  onboarding,
  mobile,
  place,
  partnerId
) {
  if (isYonja(partnerId) && osType === androidOsType) {
    return 'https://app.appsflyer.com/ru.mamba.client?pid=yonjaweb&c=onboarding_three-features_upper_touch'
  }
  if (osType === 'ios' || osType === 'webappstore') {
    return (
      'https://app.appsflyer.com/id326839545?pid=mambaweb&c=' +
      findOnboardingType(onboarding, mobile, place)
    )
  }

  if (osType === 'android' || osType === 'webplaystore') {
    return (
      'https://app.appsflyer.com/ru.mamba.client?pid=mambaweb&c=' +
      findOnboardingType(onboarding, mobile, place)
    )
  }
}

export function createAppLinkTouchByPartnerId(
  partnerId,
  osType,
  onboarding,
  touch
) {
  switch (partnerId) {
    case 963463432: // m.wamba.com
    case 958134831:
      if (osType === 'ios') {
        return 'https://app.appsflyer.com/id720041025?pid=wambaweb&c=onboarding_three-features_footer_touch'
      } else if (osType === 'android') {
        return 'https://app.appsflyer.com/com.wamba.client?pid=wambaweb&c=onboarding_three-features_footer_touch'
      }

    case 132113273: // m.love.mail.ru
    case 475306: // love.mail.ru
      if (osType === 'ios') {
        return 'https://app.appsflyer.com/id736560238?pid=mailruweb&c=onboarding_three-features_footer_touch'
      } else if (osType === 'android') {
        return 'https://app.appsflyer.com/ru.mail.love?pid=mailruweb&c=onboarding_three-features_footer_touch'
      }

    case 1374393173: // yonja.com
    case 1374393182: // m.yonja.com
      if (osType === 'ios') {
        return 'https://app.appsflyer.com/id953969253?pid=yonjaweb&c=onboarding_three-features_footer_touch'
      } else {
        return 'https://app.appsflyer.com/ru.mamba.client?pid=yonjaweb&c=onboarding_three-features_footer_touch'
      }

    case 3:
    case 81449119: // m.mamba.ru
      return createAppLinkMamba(osType, onboarding, touch)

    default:
      return null
  }
}
