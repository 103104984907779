import { isLoveMailru, isMamba, isWamba, isYonja } from 'common/constants'
import {
  appLoveMailRuLink,
  appMambaLink,
  appWambaLink,
} from 'components/presentational/DownloadApp/DownLoadAppDesktop.constants'

export const createAppUniversalDesktopLink = (partnerId: number) => {
  if (isMamba(partnerId) || isYonja(partnerId)) {
    return appMambaLink
  }

  if (isLoveMailru(partnerId)) {
    return appLoveMailRuLink
  }

  if (isWamba(partnerId)) {
    return appWambaLink
  }
}
