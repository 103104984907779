import { Cookies } from 'common-constants/Cookies'
import cookie from 'js-cookie'

export const setCookieValue = <T>(
  name: Cookies | string,
  value: T,
  expires: number
) => {
  cookie.set(name, JSON.stringify(value), {
    expires,
    secure: true,
    sameSite: 'Lax',
  })
}
