export const isServiceWorkerActive = async (): Promise<boolean> => {
  try {
    const registration = await navigator.serviceWorker.ready

    return Boolean(registration.active)
  } catch (error) {
    console.error(error)
  }

  return false
}
