import { RouterLink } from 'components/presentational/link'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLayoutContext } from 'hooks/useLayoutContext'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { supportFormPath } from '../SupportForm/SupportForm.paths'

export const SupportButton: FC = () => {
  const { baseUrl } = useLayoutContext()

  return (
    <RouterLink
      to={mergeAllUrls(baseUrl, supportFormPath)}
      data-name={'support'}
    >
      <FormattedMessage id={'app.support'} />
    </RouterLink>
  )
}
