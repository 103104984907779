import React, { Component } from 'react'
import styled from 'styled-components'

import appStore from './../../../presentational/DownloadApp/app-store.svg'
import googlePlay from './../../../presentational/DownloadApp/play-store.svg'
import { connect } from 'react-redux'
import { createAppLinkTouchByPartnerId } from 'components/presentational/DownloadApp'
import { androidOsType, iOsOsType } from 'common-constants/userAgent'
import { defaultLandingType } from 'components/landing/landing.constants'

export const DownloadAppContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const StoreIcon = ({ osType }) => {
  switch (osType) {
    case iOsOsType:
      return <img src={appStore} width="100" height="27" alt={'App Store'} />
    case androidOsType:
      return (
        <img src={googlePlay} width="105" height="24" alt={'Google Play'} />
      )
    default:
      return null
  }
}

@connect(({ systemReducer }) => ({ systemReducer }))
export default class DownloadApp extends Component {
  render() {
    const {
      systemReducer: { osType, partnerId, locale, touch },
      onboarding = defaultLandingType,
      appLink = createAppLinkTouchByPartnerId(
        partnerId,
        osType,
        onboarding,
        touch
      ),
    } = this.props

    if (osType === 'default') {
      return null
    }

    if (appLink) {
      return (
        <DownloadAppContainer>
          <a href={appLink}>
            <StoreIcon osType={osType} locale={locale} />
          </a>
        </DownloadAppContainer>
      )
    }

    return null
  }
}
